:root {
  --green-25: #edfff4;
  --green-50: #e3ffee;
  --green-300: #02cc4f;
  --green-50: #e3ffee;
  --green-500: #019c3c;
  --green-600: #008934;
  --green-700: #008934;
  --green-800: #025020;
  --gray-100: #f2f4f7;
  --gray-200: #eaecf0;
  --gray-300: #d0d5dd;
  --gray-500: #667085;
  --gray-600: #475467;
  --gray-700: #344054;
  --gray-800: #1d2939;
  --gray-900: #101828;
  --yellow-25: #fffcf5;
  --yellow-50: #fffaeb;
  --yellow-100: #fef0c7;
  --yellow-500: #f79009;
  --yellow-600: #dc6803;
  --error-50: #fef3f2;
  --error-500: #f04438;
}

* {
  box-sizing: border-box;
  margin: 0;
}

// BOX
.app_media_box {
  width: 60px;
  height: 60px;
  border: 1px solid #d0d5dd;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  img {
    width: 100%;
    object-fit: cover;
    height: 100%;
  }
  svg {
    width: 32;
    height: 32;
  }
}

.hidden {
  display: none;
}

.object-cover {
  object-fit: cover;
}

.card {
  background-color: #fff;
  border-radius: 12px;
  padding: 16px;
  /* border: 1px solid #eaecf0; */
}

.sm-card {
  background-color: #fff;
  border-radius: 12px;
  padding: 8px;
}

.cutoff-text {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
}

.cutoff-text.oneline {
  --max-lines: 1;
  -webkit-line-clamp: var(--max-lines);
}

.cutoff-text.twoline {
  --max-lines: 2;
  -webkit-line-clamp: var(--max-lines);
}

.cutoff-text.threeline {
  --max-lines: 3;
  -webkit-line-clamp: var(--max-lines);
}

.cutoff-text.fourline {
  --max-lines: 4;
  -webkit-line-clamp: var(--max-lines);
}

// BUTTON
button.chip {
  font-size: 12px;
  height: fit-content;
  padding: 6px;
  border-radius: 4px;
  color: #333;
  border: none;
  background-color: rgba(0, 0, 0, 0.02);
  font-weight: 400;
  border: 1px solid #e0e0e0;
  white-space: nowrap;
  min-width: fit-content;
  line-height: 1;
}

button.chip.selected {
  color: var(--sub-color);
  background-color: #edfff4;
}

button.chip .close-icon {
  cursor: pointer;
  margin: 0 4px;
}

.MuiInputAdornment-root {
  margin-right: 0;
}

button.fit,
.MuiCheckbox-root.fit,
.MuiRadio-root.fit {
  padding: 0;
}

.app-link-btn,
button.app-link-btn {
  padding: 8px;
  border-radius: 0;
  font-weight: 500;
  &,
  & p {
    line-height: 1.75;
  }
  &:hover {
    background-color: var(--gray-100);
  }
}

.tag-chip {
  background-color: var(--green-50);
  color: var(--green-600);
  padding: 2px 8px;
  border-radius: 6px;
}

.sponsored-label {
  width: fit-content;
  padding: 2px 8px;
  background-color: #fef0c7;
  border-radius: 21px;
  display: flex;
  gap: 4px;
  align-items: center;
  font-size: 10px;
  font-weight: 500;
  color: #f79009;
}

// ICON
.app-icon {
  position: relative;
  .prefer {
    top: -12px;
    background-color: #fffcf5;
    color: #dc6803;
    font-weight: 500;
    line-height: 12px;
    font-size: 8px;
    border-radius: 8px;
    padding: 0 5px;
    position: absolute;
    right: -20px;
    white-space: nowrap;
  }
}

// TEXT
a {
  text-decoration: none;
  color: initial;
}

.ellipsis-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.showing-result {
  color: var(--gray);
  span {
    color: var(--sub-color);
    font-weight: 700;
  }
}

.status-label.orange-label {
  background-color: #fef0c7;
  color: #93370d;
}

.status-label.gray-label {
  background-color: #f2f4f7;
  color: #667085;
}

.status-label.green-label {
  background-color: #ecfdf3;
  color: #027a48;
}

.status-label.red-label {
  background-color: var(--error-50);
  color: var(--error-500);
}

.status-label.yellow-label {
  background-color: var(--yellow-50);
  color: var(--yellow-500);
}

.status-label.brown-label {
  background-color: #fff6ed;
  color: #a96e61;
}

.status-label.blue-label {
  background-color: #f0f9ff;
  color: #026aa2;
}

.badge-number {
  background-color: #f04438;
  color: #fff;
  line-height: 14px;
  height: 15px;
  border-radius: 16px;
  font-size: 10px;
  font-weight: 500;
  min-width: 15px;
  text-align: center;
  display: inline-block;
}

.badge-number-sm {
  @extend .badge-number;
  padding: 1px 2px 1px;
  min-width: 14px;
  line-height: 12px;
}

.status-label {
  padding: 4px 10px 4px 6px;
  border-radius: 16px;
  width: fit-content;
  line-height: 1.25;
  white-space: nowrap;
  display: flex;
  gap: 8px;
  align-items: center;
}

p.placeholder,
span.placeholder,
.placeholder .MuiSelect-select {
  color: #a8a8a8;
}

// SCROLLBOX
.hor-scroll-box {
  padding-bottom: 8px;
  @media (max-width: 900px) {
    padding-bottom: 0px;
  }
}

.scroll-box {
  max-width: 100%;
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  overflow-x: scroll;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  @media (min-width: 900px) {
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 8px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 8px;
    }
  }
  @media (max-width: 900px) {
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

// Mobile view
@media only screen and (max-width: 600px) {
  .card {
    border-radius: 8px;
    padding: 8px;
  }
}
